import moment from 'moment/moment';
import { getEmployeeName } from '@/old/utils/helper';
import {
  WorkflowReportPayloadEntry,
  CommonWorkflowReportData,
  CommonWorkflowReportUnitData,
  WorkflowEntryPayload,
  WorkflowReportEmpDetail,
} from '@/app/components/WorkflowsReport/ReportTypes/Common/types';

export const commonWorkflowsReportDataMapping = (
  empDetails: WorkflowReportEmpDetail,
  workflowEntry: WorkflowEntryPayload,
): CommonWorkflowReportData => {
  return {
    empId: empDetails.fEmpId,
    firstName: empDetails.fEmpFirstName,
    lastName: empDetails.fEmpLastName,
    empNo: empDetails.fEmpPersonNumber,
    ext: !empDetails.fEmpHasAccount,

    type: workflowEntry.fWorkflowType,
    workflowId: workflowEntry.fWorkflowUid,
    group: workflowEntry.fWorkflowGroup,
    scheduledDate: moment(
      workflowEntry.fWorkflowScheduledDate,
      'YYYY-MM-DD',
    ).toISOString(),
    updatedDate: moment(workflowEntry.fWorkflowLastUpdated).toISOString(),
    approvedBy: getEmployeeName(workflowEntry.fWorkflowUpdatedByEmpId),
    status: workflowEntry.fWorkflowStatus,
  };
};

export const commonWorkflowsReportUnitDataMapping = (
  empDetails: WorkflowReportEmpDetail,
): CommonWorkflowReportUnitData => {
  return {
    unitId: empDetails.fEmpUnitDetails.fOrgUnitId,
    unit: empDetails.fEmpUnitDetails.fOrgUnitName,
    unitNo: empDetails.fEmpUnitDetails.fOrgUnitNumber,
  };
};

export const mapProposalValueChanges = <T extends object>(
  proposal: T,
  reference: T,
): { proposal: T; changes: Partial<T> } => {
  const changes = Object.keys(reference).reduce((acc, key) => {
    const refValue = reference[key];

    if (proposal[key] !== refValue) {
      return {
        ...acc,
        [key]: refValue,
      };
    }

    return acc;
  }, {});

  return { proposal, changes };
};

// Workflows Proposal Status sorting order
enum WorkflowProposalStatus {
  CREATED = 'CREATED',
  PROPOSED = 'PROPOSED',
  MANAGER_REJECTED = 'MANAGER_REJECTED',
  MANAGER_APPROVED = 'MANAGER_APPROVED',
  HR_REJECTED = 'HR_REJECTED',
  HR_APPROVED = 'HR_APPROVED',
  COMPLETED = 'COMPLETED',
  CLOSED = 'CLOSED',
  FAILED = 'FAILED',
  ARCHIVED = 'ARCHIVED',
}

export const commonWorkflowsDataStructureMappingForUI = <
  TPayload,
  TData extends WorkflowReportPayloadEntry<TPayload>,
  TEntry extends CommonWorkflowReportData,
>(
  data: TData[],
  mapToEntry: (
    details: WorkflowReportEmpDetail,
    workflowEntry: WorkflowEntryPayload,
    proposal: TPayload,
    reference: TPayload,
  ) => TEntry,
): TEntry[] => {
  const statusOrder = Object.values(WorkflowProposalStatus).map(s => s.toString());
  const sortByStatus = (a: TEntry, b: TEntry): number => statusOrder.indexOf(b.status) > statusOrder.indexOf(a.status) ? 1 : -1;
  const sortByUpdatedTime = (a: TEntry, b: TEntry): number => b.updatedDate > a.updatedDate ? 1 : -1;

  return data
    .map(reportEmployee => {
      const { fEmpDetails: details, fProposals: workflows } = reportEmployee;
      return workflows
        .map(workflow => {
          const {
            fWorkflowEntries,
            fReferenceData: { translate: reference },
            fProposal: { translate: proposal },
          } = workflow;

          return fWorkflowEntries.map(workflowEntry =>
            mapToEntry(details, workflowEntry, proposal, reference),
          ).sort(sortByStatus).sort(sortByUpdatedTime);
        })
        .flat();
    })
    .flat();
};
