import {
  getCurrentLanguage,
  getCompanyDefaultLanguage,
} from '@/app/utils/helper';
import { TRAINING_TYPE } from '@/app/components/Training/TrainingList/TrainingList';
import { SURVEY_STATUS } from '../redux/surveys';
import { WorkflowReportTypes } from '@/app/components/WorkflowsReport/ReportTypes';

const SOLAPULSE_API = '/d/json/solapulse/survey';

const getClosedWorfklowReportRequestPath = (closedWorkflowType: string) => {
  switch (closedWorkflowType) {
    case 'peopleIntPositionsClosedWorkflows':
      return 'closedworkflow/intpositions';
    case 'peopleEmpDetailsClosedWorkflows':
      return 'closedworkflow/empldetails';
    case 'rewardingClosedWorkflows':
    default:
      return 'openworkflowfull';
  }
};

const getWorkflowsReportRequestPath = (reportType: WorkflowReportTypes) => {
  switch (reportType) {
    case WorkflowReportTypes.EmployeeDetailsClosed:
      return 'closedworkflow/empldetails';
    case WorkflowReportTypes.EmployeeDetailsOpen:
      return 'openworkflow/empldetails';
    case WorkflowReportTypes.InternalPositionClosed:
      return 'closedworkflow/intpositions';
    case WorkflowReportTypes.InternalPositionOpen:
      return 'openworkflow/intpositions';
    case WorkflowReportTypes.RewardingClosed:
    case WorkflowReportTypes.RewardingOpen:
    default:
      return 'openworkflowfull';
  }
};

const APIs = {
  adminSection: {
    getAllTabs: () => '/d/json/enum/MODULE_TAB',
    getActiveTabs: (module: string) => `/d/json/company/${module}/tabs`,
    setActiveTabs: (module: string) => `d/json/company/${module}/tabs`,
  },
  translate: {
    getProposalTranslation: (proposalId: string) =>
      `/d/json/recruiting/opening/${proposalId}/translate`,
    putProposalTranslation: (proposalId: string) =>
      `/d/json/recruiting/opening/${proposalId}/translate`,
  },
  goalTemplates: {
    getFullTemplate: (templateId: string, lang: string) =>
      `/d/json/performance/goals/templates/${templateId}?langIso=${lang}`,
    getAllCommonGoals: () =>
      `/d/json/enum/PERF_GOAL_V2/${getCurrentLanguage()}?format=extended&includeInactive=false`,
    getAllTemplates: () => `/d/json/performance/goals/templates/full`,
    getAllFilteredTemplates: (empId: string) =>
      `/d/json/performance/goals/templates/full/${empId}`,
    addTemplate: () => '/d/json/performance/goals/templates/create',
    updateTemplate: (templateId: string) =>
      `/d/json/performance/goals/templates/${templateId}/update`,
    removeTemplates: (templateId: string) =>
      `/d/json/performance/goals/templates/${templateId}`,
    activateTemplates: () => '/d/json/performance/goals/templates/ACTIVATE',
    deactivateTemplates: () => '/d/json/performance/goals/templates/DEACTIVATE',
    translateTemplate: (templateId: string) =>
      `/d/json/performance/goals/templates/${templateId}/translate`,
    duplicateTemplate: (templateId: string) =>
      `/d/json/performance/goals/templates/${templateId}/duplicate`,
    addSection: (templateId: string) =>
      `/d/json/performance/goals/templates/${templateId}/add-section`,
    updateSection: (sectionId: string) =>
      `/d/json/performance/goals/sections/${sectionId}/update`,
    deleteSection: (sectionId: string) =>
      `/d/json/performance/goals/sections/${sectionId}`,
    translateSection: (sectionId: string) =>
      `/d/json/performance/goals/sections/${sectionId}/translate`,
    addComponent: (sectionId: string) =>
      `/d/json/performance/goals/sections/${sectionId}/add-component`,
    updateComponent: (componentId: string) =>
      `/d/json/performance/goals/components/${componentId}/update`,
    orderComponents: (sectionId: string) =>
      `/d/json/performance/goals/sections/${sectionId}/order-components`,
    deleteComponent: (componentId: string) =>
      `/d/json/performance/goals/components/${componentId}`,
  },
  appraisalsTemplates: {
    getAllTemplates: () => '/d/json/appraisal/templates/full',
    addAppraisalTemplate: () => '/d/json/appraisal/templates/create',
    updateTemplate: (templateId: string) =>
      `/d/json/appraisal/templates/${templateId}/update`,
    activateTemplate: () => '/d/json/appraisal/templates/activate',
    deactivateTemplate: () => '/d/json/appraisal/templates/deactivate',
    removeTemplates: () => '/d/json/appraisal/templates/delete',
    duplicateTemplate: (templateId: string) =>
      `/d/json/appraisal/templates/${templateId}/duplicate`,
    addSection: (templateId: string) =>
      `/d/json/appraisal/templates/${templateId}/add-section`,
    updateSection: (sectionId: string) =>
      `/d/json/appraisal/sections/${sectionId}/update`,
    deleteSection: (sectionId: string) =>
      `/d/json/appraisal/sections/${sectionId}`,
    addComponent: (sectionId: string) =>
      `/d/json/appraisal/sections/${sectionId}/add-component`,
    updateComponent: (componentId: string) =>
      `/d/json/appraisal/components/${componentId}/update`,
    orderComponents: (sectionId: string) =>
      `/d/json/appraisal/sections/${sectionId}/order-components`,
    deleteComponent: () => '/d/json/appraisal/components/delete',
  },
  jobEvaluationTemplates: {
    getAllTemplates: () => '/d/json/job-requirement/templates/full',
    addJobEvaluationTemplate: () => '/d/json/job-requirement/templates/create',
    updateTemplate: (templateId: string) =>
      `/d/json/job-requirement/templates/${templateId}/update`,
    activateTemplate: () => '/d/json/job-requirement/templates/activate',
    deactivateTemplate: () => '/d/json/job-requirement/templates/deactivate',
    removeTemplates: () => '/d/json/job-requirement/templates/delete',
    duplicateTemplate: (templateId: string) =>
      `/d/json/job-requirement/templates/${templateId}/duplicate`,
    addSection: (templateId: string) =>
      `/d/json/job-requirement/templates/${templateId}/add-section`,
    updateSection: (sectionId: string) =>
      `/d/json/job-requirement/sections/${sectionId}/update`,
    deleteSection: (sectionId: string) =>
      `/d/json/job-requirement/sections/${sectionId}`,
    addComponent: (sectionId: string) =>
      `/d/json/job-requirement/sections/${sectionId}/add-component`,
    updateComponent: (componentId: string) =>
      `/d/json/job-requirement/components/${componentId}/update`,
    orderComponents: (sectionId: string) =>
      `/d/json/job-requirement/sections/${sectionId}/order-components`,
    getScoreGroups: () => '/d/json/job-requirement/score-group/get',
    createScoreGroup: () => '/d/json/job-requirement/score-group/create',
    updateScoreGroup: (scoreGroupId: string) =>
      `/d/json/job-requirement/score-group/${scoreGroupId}/update`,
    deleteScoreGroup: (scoreGroupId: string) =>
      `/d/json/job-requirement/score-group/${scoreGroupId}/delete`,
    deleteScoreGroups: () => `/d/json/job-requirement/score-group/delete`,
    deleteComponent: () => '/d/json/job-requirement/components/delete',
  },
  workTimeSettings: {
    defaultHoursSettings: () => `/d/json/company/config`,
    getCountryHoursSettings: (countryCode: string) =>
      `/d/json/timesheet/region-settings/${countryCode}`,
    createCountryHoursSettings: () => '/d/json/timesheet/region-settings',
    updateCountryHoursSettings: (countryCode: string) =>
      `/d/json/timesheet/region-settings/${countryCode}`,
    getTimeSheet: (employeeId: string) =>
      `/d/json/timesheet/employee/${employeeId}/timesheet`,
    getTimeCards: (employeeId: string, startDate: string, endDate: string) =>
      `/d/json/timesheet/employee/${employeeId}/timecards?startDate=${startDate}&endDate=${endDate}`,
    updateTimeCard: (employeeId: string) =>
      `/d/json/timesheet/employee/${employeeId}/timecard`,
    deleteTimeCard: (employeeId: string, date: string) =>
      `/d/json/timesheet/employee/${employeeId}/timecard/${date}`,
    getPrevRegisteredTimeCardWorkingHours: (employeeId: string, date: string) =>
      `/d/json/timesheet/employee/${employeeId}/workhours/${date}/default`,
  },
  workTimeAutoDeduction: {
    getRules: () => '/d/json/timesheet/autodeduction',
    getRuleForEmployee: (employeeId: string) =>
      `/d/json/timesheet/autodeduction/${employeeId}`,
    createRule: () => '/d/json/timesheet/autodeduction',
    editRule: (ruleId: number) => `/d/json/timesheet/autodeduction/${ruleId}`,
    deleteRule: (ruleId: number) => `/d/json/timesheet/autodeduction/${ruleId}`,
  },
  goalEvaluations: {
    getAllCommonGoals: () =>
      `/d/json/enum/PERF_GOAL_V2/${getCurrentLanguage()}?format=extended&includeInactive=false`,
    getTemplateList: () => '/d/json/performance/goals/templates/full',
    getAllFilteredTemplates: (empId: string) =>
      `/d/json/performance/goals/templates/full/${empId}`,
    getEvaluations: (employeeId: number) =>
      `/d/json/performance/goals/employee/${employeeId}/evaluations`,
    approveEvaluation: (evaluationId: string) =>
      `/d/json/performance/goals/evaluations/${evaluationId}/approve`,
    unapproveEvaluation: (evaluationId: string) =>
      `/d/json/performance/goals/evaluations/${evaluationId}/unapprove`,
    archiveEvaluation: (evaluationId: string) =>
      `/d/json/performance/goals/evaluations/${evaluationId}/archive`,
    saveEvaluation: (templateId: string) =>
      `/d/json/performance/goals/templates/${templateId}/evaluate`,
    getSuccessMeasureSettings: (goalSubgroupName: string) =>
      `d/json/performance/${goalSubgroupName}`,
  },
  appraisalsEvaluations: {
    getTemplateList: (empId: string) =>
      `/d/json/appraisal/templates/full/${empId}`,
    getEvaluationDetails: (templateId: string, employeeId: number) =>
      `/d/json/appraisal/templates/${templateId}/employee/${employeeId}/evaluation`,
    getArchivedEvaluations: (employeeId: number) =>
      `/d/json/appraisal/archived-evaluations/employee/${employeeId}`,
    getArchivedEvaluationDetails: (evaluationId: string) =>
      `/d/json/appraisal/archived-evaluations/${evaluationId}`,
    approveEvaluation: (evaluationId: string) =>
      `/d/json/appraisal/evaluations/${evaluationId}/approve`,
    archiveEvaluation: (evaluationId: string) =>
      `/d/json/appraisal/evaluations/${evaluationId}/archive`,
    unapproveEvaluation: (evaluationId: string) =>
      `/d/json/appraisal/evaluations/${evaluationId}/unapprove`,
    saveEvaluation: (templateId: string) =>
      `/d/json/appraisal/templates/${templateId}/evaluate`,
    uploadFile: () => `/d/form/appraisal/evaluations/file`,
  },
  jobRequirementEvaluations: {
    getTemplateList: () => '/d/json/job-requirement/templates/full',
    getEvaluationDetails: (templateId: string, employeeId: number) =>
      `/d/json/job-requirement/templates/${templateId}/employee/${employeeId}/evaluation`,
    getArchivedEvaluations: (employeeId: number) =>
      `/d/json/job-requirement/archived-evaluations/employee/${employeeId}`,
    getArchivedEvaluationDetails: (evaluationId: string) =>
      `/d/json/job-requirement/archived-evaluations/${evaluationId}`,
    approveEvaluation: (evaluationId: string) =>
      `/d/json/job-requirement/evaluations/${evaluationId}/approve`,
    archiveEvaluation: (evaluationId: string) =>
      `/d/json/job-requirement/evaluations/${evaluationId}/archive`,
    unapproveEvaluation: (evaluationId: string) =>
      `/d/json/job-requirement/evaluations/${evaluationId}/unapprove`,
    saveEvaluation: (templateId: string) =>
      `/d/json/job-requirement/templates/${templateId}/evaluate`,
    getScoreGroups: () => `/d/json/job-requirement/score-group/get`,
  },
  processTemplates: {
    getAllTemplates: () => '/d/json/process-templates/templates/full',
    addProcessTemplate: () => '/d/json/process-templates/templates/create',
    updateTemplate: (templateId: string) =>
      `/d/json/process-templates/templates/${templateId}/update`,
    activateTemplate: () => '/d/json/process-templates/templates/activate',
    deactivateTemplate: () => '/d/json/process-templates/templates/deactivate',
    removeTemplates: () => '/d/json/process-templates/templates/delete',
    duplicateTemplate: (templateId: string) =>
      `/d/json/process-templates/templates/${templateId}/duplicate`,
    addSection: (templateId: string) =>
      `/d/json/process-templates/templates/${templateId}/add-section`,
    updateSection: (sectionId: string) =>
      `/d/json/process-templates/sections/${sectionId}/update`,
    deleteSection: (sectionId: string) =>
      `/d/json/process-templates/sections/${sectionId}`,
    addComponent: (sectionId: string) =>
      `/d/json/process-templates/sections/${sectionId}/add-component`,
    updateComponent: (componentId: string) =>
      `/d/json/process-templates/components/${componentId}/update`,
    orderComponents: (sectionId: string) =>
      `/d/json/process-templates/sections/${sectionId}/order-components`,
    deleteComponent: () => '/d/json/process-templates/components/delete',
  },
  processEvaluations: {
    getTemplateList: (empId: string) =>
      `/d/json/process-templates/templates/full/${empId}`,
    getEvaluationDetails: (templateId: string, employeeId: number) =>
      `/d/json/process-templates/templates/${templateId}/employee/${employeeId}/evaluation`,
    getArchivedEvaluations: (employeeId: number) =>
      `/d/json/process-templates/archived-evaluations/employee/${employeeId}`,
    getArchivedEvaluationDetails: (evaluationId: string, employeeId: number) =>
      `/d/json/process-templates/archived-evaluations/${evaluationId}/employee/${employeeId}`,
    approveEvaluation: (evaluationId: string) =>
      `/d/json/process-templates/evaluations/${evaluationId}/approve`,
    archiveEvaluation: (evaluationId: string) =>
      `/d/json/process-templates/evaluations/${evaluationId}/archive`,
    unapproveEvaluation: (evaluationId: string) =>
      `/d/json/process-templates/evaluations/${evaluationId}/unapprove`,
    saveEvaluation: (templateId: string) =>
      `/d/json/process-templates/templates/${templateId}/evaluate`,
  },
  workflow: {
    salaryproposal: (employeeId: number, employmentId: number, uid: string) =>
      `/d/json/employee/${employeeId}/employment/${employmentId}/salaryproposal/${uid}/workflow`,
    benefitproposal: (employeeId: number, employmentId: number, uid: string) =>
      `/d/json/employee/${employeeId}/employment/${employmentId}/benefitproposal/${uid}/workflow`,
    allowanceproposal: (
      employeeId: number,
      employmentId: number,
      uid: string,
    ) =>
      `/d/json/employee/${employeeId}/employment/${employmentId}/allowanceproposal/${uid}/workflow`,
    bonusproposal: (employeeId: number, employmentId: number, uid: string) =>
      `/d/json/employee/${employeeId}/employment/${employmentId}/incentiveshortproposal/${uid}/workflow`,
    billableHoursProposal: (
      employeeId: number,
      employmentId: number,
      uid: string,
    ) =>
      `/d/json/employee/${employeeId}/employment/${employmentId}/billablehoursproposal/${uid}/workflow`,
  },
  employeeImageUrl: (employeeId: number) =>
    `/d/bin/employee/profile/picture/${employeeId}`,
  training: {
    list: `/d/json/company/dev/edu/training`,
    listByType: (type: TRAINING_TYPE) =>
      `/d/json/company/dev/edu/training?trainingType=${type}`,
    listNamesByType: (type: TRAINING_TYPE, includeInactive = false) =>
      `/d/json/enum/${type}/${getCurrentLanguage()}?includeInactive=${includeInactive}`,
    find: '',
    add: '/d/json/company/dev/edu/training',
    update: (trainingId: number) =>
      `/d/json/company/dev/edu/training/${trainingId}`,
    updateActive: '/d/json/company/dev/edu/training/updatestatus',
    updateNames: (type: TRAINING_TYPE) => `/d/json/enum/${type}`,
    delete: '/d/json/company/dev/edu/training',
    sendEmail: (trainingId: number) =>
      `/d/json/dev/edu/company/trainings/${trainingId}/send-email`,
    getEmailTemplates: () => 'd/json/training/mail?format=full',
    downloadTemplate:
      'd/json/dev/edu/company/trainings/import/participantsexample',
    participants: (trainingId: number) =>
      `d/json/dev/edu/company/trainings/${trainingId}/participants`,
    companyTraining: (trainingId: number) =>
      `/d/json/dev/edu/company/trainings/${trainingId}`,
  },
  uiLabels: {
    listByLangCode: (langCode: string) =>
      `/d/json/enum/UI_LABEL/${langCode}?includeInactive=false`,
    list: () => '/d/json/enum/UI_LABEL?format=full&includeInactive=false',
    putLabels: () => '/d/json/enum/UI_LABEL',
    updateLabel: (langCode: string, code: string) =>
      `/d/json/enum/UI_LABEL/${langCode}/${encodeURIComponent(code)}`,
  },
  enum: {
    listValuesByGroup: (
      group: string,
      companyDefaultLanguage = getCompanyDefaultLanguage(),
    ) =>
      `/d/json/enum/${group}/${companyDefaultLanguage}?includeInactive=false`,
    translation: (group: string, code: string) =>
      `/d/json/enum/${group}/v/${code}/loc`,
    details: (group: string, code: string) =>
      `/d/json/enum/${group}/v/${code}/details`,
    add: (group: string) => `/d/json/enum/${group}/v`,
    get: (group: string, name: string) => `/d/json/enum/${group}/d/${name}`,
    update: (group: string, code: string) => `/d/json/enum/${group}/v/${code}/`,
    groupCodes: (
      group: string,
      companyDefaultLanguage = getCompanyDefaultLanguage(),
    ) =>
      `/d/json/enum/${group}/${companyDefaultLanguage}?includeInactive=false`,
    group: (group: string) => `/d/json/enum/${group}/v`,
    groupCode: (group: string, code: string) =>
      `/d/json/enum/${group}/v/${code}`,
    groupTags: (group: string) => `/d/json/enum/${group}/tag`,
    all: (currentLanguage = getCurrentLanguage()) =>
      `/d/json/enum/ALL/${currentLanguage}`,
    allWithConfig: '/d/json/enum?format=all',
    order: (group: string) => `d/json/enum/${group}/order`,
    currentVersion: '/d/json/enum/currentVersion',
    customFieldAttribute: (name: string) =>
      `/d/json/customdata/entity/${name}/attribute`,
    updateCustomFieldAttribute: (group: string, code: string) =>
      `/d/json/customdata/entity/${group}/attribute/${code}`,
    deleteCustomFieldAttribute: (group: string, code: string) =>
      `/d/json/customdata/entity/${group}/attribute/${code}/h`,
    customFields: (name: string, iEmpId: string) =>
      `d/json/customdata/${name}/${iEmpId}`,
  },
  group: {
    post: (module: string, name: string) =>
      `/d/json/${module}/enum/user/${name}`,
    getGroupValues: (name: string) => `/d/json/enum/${name}`,
    delete: (module: string, name: string) =>
      `/d/json/${module}/enum/user/${name}`,
  },
  survey: {
    answer: () => '/d/json/survey',
  },
  solaPulse: {
    create: `${SOLAPULSE_API}/create`,
    schedule: (id: string) => `${SOLAPULSE_API}/${id}/schedule`,
    start: (id: string) => `${SOLAPULSE_API}/${id}/start`,
    close: (id: string) => `${SOLAPULSE_API}/${id}/close`,
    publish: (id: string) => `${SOLAPULSE_API}/${id}/publish`,
    unpublish: (id: string) => `${SOLAPULSE_API}/${id}/unpublish`,
    getNotifications: `${SOLAPULSE_API}/notifications`,
    get: (id: string, lang: string = getCurrentLanguage()) =>
      `${SOLAPULSE_API}/${id}?langIso=${lang}`,
    getAll: (
      onlyOwned = false,
      lang = getCurrentLanguage(),
      status?: SURVEY_STATUS,
    ) =>
      !!status
        ? `${SOLAPULSE_API}?onlyOwned=${onlyOwned}&langIso=${lang}&status=${status}`
        : `${SOLAPULSE_API}?onlyOwned=${onlyOwned}&langIso=${lang}`,
    bulkDelete: `${SOLAPULSE_API}/batch/delete`,
    delete: (id: string) => `${SOLAPULSE_API}/${id}`,
    view: (id: string) => `${SOLAPULSE_API}/${id}/view`,
    answer: (id: string) => `${SOLAPULSE_API}/${id}/answer`,
    surveyReport: (id: string, unitId?: number) =>
      !unitId
        ? `${SOLAPULSE_API}/${id}/report`
        : `${SOLAPULSE_API}/${id}/report?unitId=${unitId}`,
    translate: (id: string) => `${SOLAPULSE_API}/${id}/translate`,
    update: (id: string) => `${SOLAPULSE_API}/${id}/update`,
    changeCloseDate: (id: string) => `${SOLAPULSE_API}/${id}/closeDate`,
    addAudience: (id: string) => `${SOLAPULSE_API}/${id}/addAudience`,
    changeReviewers: (id: string) => `${SOLAPULSE_API}/${id}/reviewers`,
  },
  getCurrency: () => 'd/json/company/currency',
  bonus: {
    rule: (ruleId: number) => `/d/json/bonuscalc/rules/${ruleId}`,
    rules: '/d/json/bonuscalc/rules',
    toggleRules: '/d/json/bonuscalc/rules/setactive',
    activeRules: '/d/json/bonuscalc/rules?isActive=true',
    programs: 'bonusCalc/programs',
    plan: (unitId: number) => `/d/json/planning/bonuscalc/unit/${unitId}/emp`,
    bulkProposal: '/d/json/incentiveshortproposals',
  },
  approveDocument: {
    send: '/d/json/document-approval/send',
    code: (id: number) => `/d/json/document-approval/${id}/code`,
    token: (id: number) => `/d/json/document-approval/${id}/token`,
    approve: (id: number) => `/d/json/document-approval/${id}/approve`,
    reject: (id: number) => `/d/json/document-approval/${id}/reject`,
    allDocsHistory: (empId: number) =>
      `/d/json/employee/${empId}/document-approvals`,
    docHistory: (empId: number, id: number) =>
      `/d/json/employee/${empId}/file/${id}/document-approvals`,
    docApprovalFile: (id: number) => `/d/bin/document-approval/${id}/file`,
  },
  v2ApproveDocument: {
    config: '/api/documents/config',
    send: (docId: number) => `/api/documents/${docId}/approvals/send`,
    docHistory: (docId: number) => `/api/documents/${docId}/approvals`,
    approve: (docId: number) => `/api/documents/${docId}/approvals/approve`,
    cancel: (docId: number) => `/api/documents/${docId}/approvals/cancel`,
  },
  solaIntel: {
    allIndicators: '/d/json/solaintel/indicator?includeInactive=true',
    allIndicatorsSummary: (unitId: string) =>
      `/d/json/solaintel/indicator/summary/unit/${unitId}`,
    filteredIndicators: (
      includeInactive: boolean = false,
      category: string = '',
      outputType: string = '',
    ) =>
      `/d/json/solaintel/indicator?category=${category}&outputType=${outputType}&includeInactive=${includeInactive}`,
    indicator: (id: string) => `/d/json/solaintel/indicator/${id}`,
    updateIndicator: (id: string) =>
      `/d/json/solaintel/predefined-indicator/${id}/update`,
    indicatorReportResults: (id: string, unitId: string) =>
      `/d/json/solaintel/indicator/${id}/unit/${unitId}/results`,
    createGroupedIndicator: '/d/json/solaintel/grouped-indicator/create',
    updateGroupedIndicator: (id: string) =>
      `/d/json/solaintel/grouped-indicator/${id}/update`,
    deleteGroupedIndicator: (id: string) => `/d/json/solaintel/indicator/${id}`,
  },
  orgTree: {
    primaryUnits: () => '/d/json/org/tree/1/unit',
    myManagedUnits: (managerId: number) =>
      `/d/json/org/tree/1/manager/${managerId}/unitsub`,
    myHrUnits: (hrId: number) => `/d/json/org/tree/HR/${hrId}/unitsub`,
    myAltManagerUnits: (altManagerId: number) =>
      `/d/json/org/tree/ALT_MANAGER/${altManagerId}/unitsub`,
  },
  company: {
    configWorkflow: 'd/json/company/config/workflow',
  },
  collaboration: {
    addTask: 'd/json/collab/task',
    tasksByAssignee: (empId: number) => `d/json/collab/task/assignee/${empId}`,
    tasksByAssignor: (empId: number) => `d/json/collab/task/assignor/${empId}`,
    tasksByPertains: (empId: number) => `d/json/collab/task/pertains/${empId}`,
    attachment: (taskId: number, fileId: number) =>
      `/d/bin/collab/task/${taskId}/file/${fileId}`,
    comments: (taskId: number) => `/d/json/collab/task/${taskId}/comment`,
  },
  worklists: {
    all: 'd/json/collab/worklist',
    add: 'd/json/collab/worklist',
    addTask: (worklistId: number) =>
      `d/form/collab/worklist/${worklistId}/task`,
    delete: `/d/json/collab/worklist`,
    deleteTask: (worklistId: number) =>
      `d/json/collab/worklist/${worklistId}/task`,
    taskAttachment: (taskId: number, fileId: number) =>
      `/d/bin/collab/worklist/task/${taskId}/file/${fileId}`,
    get: (id: number) => `/d/json/collab/worklist/${id}?format=FULL`,
    update: (id: number) => `/d/json/collab/worklist/${id}`,
    updateTask: (worklistId: number, taskId: number) =>
      `d/form/collab/worklist/${worklistId}/task/${taskId}`,
  },
  workflowsReport: {
    getByManagerId: (
      reportType: WorkflowReportTypes,
      managerType: string,
      employeeId: number,
    ) =>
      `d/json/report/${managerType}/${employeeId}/direct/${getWorkflowsReportRequestPath(
        reportType,
      )}`,
    getByManagerAllId: (reportType: WorkflowReportTypes, employeeId: number) =>
      `d/json/report/manager/${employeeId}/all/${getWorkflowsReportRequestPath(
        reportType,
      )}`,
    getByHrId: (reportType: WorkflowReportTypes, employeeId: number) =>
      `d/json/report/hr/${employeeId}/all/${getWorkflowsReportRequestPath(
        reportType,
      )}`,
    getByUnitId: (reportType: WorkflowReportTypes, unitId: number) =>
      `d/json/report/unit/${unitId}/direct/${getWorkflowsReportRequestPath(
        reportType,
      )}`,
    getBySubUnitId: (reportType: WorkflowReportTypes, unitId: number) =>
      `d/json/report/unit/${unitId}/all/${getWorkflowsReportRequestPath(
        reportType,
      )}`,
  },
  closedWorkflowsReport: {
    getByManagerId: (
      closedWorkflowType: string,
      managerType: string,
      id: number,
    ) =>
      `d/json/report/${managerType}/${id}/direct/${getClosedWorfklowReportRequestPath(
        closedWorkflowType,
      )}`,
    getByManagerAllId: (closedWorkflowType: string, id: number) =>
      `d/json/report/manager/${id}/all/${getClosedWorfklowReportRequestPath(
        closedWorkflowType,
      )}`,
    getByHrId: (closedWorkflowType: string, id: number) =>
      `d/json/report/hr/${id}/all/${getClosedWorfklowReportRequestPath(
        closedWorkflowType,
      )}`,
    getByUnitId: (closedWorkflowType: string, id: number) =>
      `d/json/report/unit/${id}/direct/${getClosedWorfklowReportRequestPath(
        closedWorkflowType,
      )}`,
    getBySubUnitId: (closedWorkflowType: string, id: number) =>
      `d/json/report/unit/${id}/all/${getClosedWorfklowReportRequestPath(
        closedWorkflowType,
      )}`,
  },
  worktimeReport: {
    getReport: (
      scope: Scope,
      scopeId: number,
      relation: Relation,
      startDate: string,
      endDate: string,
    ) =>
      `d/json/report/${scope}/${scopeId}/${relation}/timereporting?startDate=${startDate}&endDate=${endDate}`,
  },
  workingHoursSummaryReport: {
    getReport: (
      scope: Scope,
      scopeId: number,
      relation: Relation,
      startDate: string,
      endDate: string,
    ) =>
      `d/json/report/${scope}/${scopeId}/${relation}/timereportingsummary?startDate=${startDate}&endDate=${endDate}`,
  },
  substituteManagerReport: {
    getReport: (
      scope: Scope,
      scopeId: number,
      relation: Relation,
      startDate: string,
      endDate: string,
    ) =>
      `/d/json/report/${scope}/${scopeId}/${relation}/orgsubresp?startDate=${startDate}&endDate=${endDate}`,
  },
  vacationBalancesReport: {
    getReport: (scope: Scope, scopeId: number, relation: Relation) =>
      `/d/json/report/${scope}/${scopeId}/${relation}/absencevacationlist`,
  },
  trainings: {
    getInternal: (empId: number) =>
      `/d/json/employee/${empId}/dev/edu/training/internal`,
    getExternal: (empId: number) =>
      `/d/json/employee/${empId}/dev/edu/training/external`,
    getOther: (empId: number) =>
      `/d/json/employee/${empId}/dev/edu/training/other`,
  },
  employee: {
    attachment: (empId: number, fileId: number) =>
      `/d/bin/employee/${empId}/file/${fileId}`,
    basic: (empId: string | number) => `/d/json/employee/${empId}/basic`,
    new: () => `/d/json/employee/new`,
    list: () => `/d/json/employee/full?format=SHORT`,
    ssn: (ssn: string) => `/d/json/employee/ssn/${ssn}`,
    pendingList: () => `/d/json/employee/full/PENDING?format=short`,
  },
  AppraisalReport: {
    getAppraisalTemplates: () => `d/json/appraisal/templates`,
    getAppraisalReport: (
      userRole: string,
      userId: number,
      relation: Relation,
    ) => `d/json/report/${userRole}/${userId}/${relation}/appraisal`,
    getAppraisalReportCustom: (
      userRole: string,
      userId: number,
      relation: Relation,
      selectedTemplates: string[],
    ) =>
      `/d/json/report/${userRole}/${userId}/${relation}/appraisal/full?selectedTemplates=${selectedTemplates.join()}`,
    getAppraisalFullReport: (
      userRole: string,
      userId: number,
      relation: Relation,
    ) => `/d/json/report/${userRole}/${userId}/${relation}/appraisal/full`,
  },
  JobEvaluationReport: {
    getJobEvaluationTemplates: () => `d/json/job-requirement/templates`,
    getJobEvaluationReport: (
      userRole: string,
      userId: number,
      relation: Relation,
    ) => `d/json/report/${userRole}/${userId}/${relation}/job-requirement`,
    getJobEvaluationReportCustom: (
      userRole: string,
      userId: number,
      relation: Relation,
      selectedTemplates: string[],
    ) =>
      `/d/json/report/${userRole}/${userId}/${relation}/job-requirement/full?selectedTemplates=${selectedTemplates.join()}`,
    getJobEvaluationFullReport: (
      userRole: string,
      userId: number,
      relation: Relation,
    ) =>
      `/d/json/report/${userRole}/${userId}/${relation}/job-requirement/full`,
  },
  GoalsReport: {
    getGoalsReport: (
      userRole: string,
      userId: number,
      relation: Relation,
      scope: string,
    ) =>
      ` /d/json/report/${userRole}/${userId}/${relation}/goals?empScope=${scope}`,
  },
  ProcessReport: {
    getProcessReport: (userRole: string, userId: number, relation: Relation) =>
      `d/json/report/${userRole}/${userId}/${relation}/process-templates`,
    getProcessFullReport: (
      userRole: string,
      userId: number,
      relation: Relation,
    ) =>
      `/d/json/report/${userRole}/${userId}/${relation}/process-templates/full`,
  },
  quickLinks: {
    get: (language: string) =>
      `api/people/quick-links?lang=${language}&full=true`,
    add: (language: string) => `api/people/quick-links?lang=${language}`,
    update: (id: string) => `api/people/quick-links/${id}`,
    delete: (id: string) => `api/people/quick-links/${id}`,
    bulkDelete: () => `api/people/quick-links/bulk-delete`,
    updateTranslations: (id: string) =>
      `api/people/quick-links/${id}/translations`,
    updateOrder: () => `api/people/quick-links/order`,
  },
  forms: {
    createForm: () => '/api/forms/forms',
    getAllForms: () =>
      '/api/forms/forms?select=title,name,path,settings,properties',
    getBuilder: (
      type: 'basic' | 'full' | 'employment',
      language: string,
      noPrefix?: boolean,
    ) =>
      `${
        !noPrefix ? '/api/forms' : ''
      }/builders/${type}/options?lang=${language}`,
    getFormsComponents: (formId: string) => `/api/forms/forms/${formId}/components`,
    getCustomPageFormsComponents: () => `/api/forms/forms/components/custompage`,
    formGeneral: (formId: string, noPrefix?: boolean) =>
      `${!noPrefix ? '/api/forms' : ''}/forms/${formId}`,
    formProvisionalEmpSubmission: (empNumber: string, noPrefix?: boolean) =>
      `${!noPrefix ? '/api/forms' : ''}/forms/submissions/${empNumber}`,
    formSubmissions: (formId: string, noPrefix?: boolean) =>
      `${!noPrefix ? '/api/forms' : ''}/forms/${formId}/submissions`,
    formDefault: (type: 'basic' | 'full' | 'employment', noPrefix?: boolean) =>
      `${!noPrefix ? '/api/forms' : ''}/builder/${type}/form`,
    translations: (formId: string) => `/api/forms/forms/${formId}/i18n`,
  },
  entityFieldTranslations: {
    get: (entityName: string, id: string, property: string) =>
      `/d/json/translations/entity/${entityName}/${id}/${property}`,
    update: (entityName: string, id: string, property: string) =>
      `/d/json/translations/entity/${entityName}/${id}/${property}`,
  },
  unitPositionReport: {
    getReport: (scope: Scope, scopeId: number, relation: Relation) =>
      `/d/json/report/${scope}/${scopeId}/${relation}/unitposition`,
  },
  user: {
    get: (userName: string) => `/d/json/user/${userName}`,
  },
  workExperience: (dateParam: string, userId: number) =>
    `/d/json/report/EMPLOYEE/${userId}/DIRECT/workexperience?${dateParam}`,
  dataRetention: () => '/d/json/company/dataretention',
  dataRetentionFull: () => '/d/json/company/dataretention/full',
  deleteDataRetention: (module: string) =>
    `/d/json/company/dataretention/${module}`,
};

export enum Relation {
  DIRECT = 'DIRECT',
  ALL = 'ALL',
}

export enum Scope {
  UNIT = 'UNIT',
  HR = 'HR',
  MANAGER = 'MANAGER',
  ALT_MANAGER = 'AMGR',
  EMPLOYEE = 'EMPLOYEE',
}

export default APIs;
