import * as React from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  Tooltip,
  TableSortLabel,
} from '@material-ui/core';
import translate from '@/app/utils/translate';
import { Id } from '../EnhancedTable';
import { Tools } from '@/app/components/EnhancedTable/EnhancedTableToolbar/EnhancedTableToolbar';

/**
 * Table head's data
 * @id key of the data, string usually
 * @type if the data is number, text align to right; array for string[], ChildInputTypeArray for ChildInputType[]
 * @label Text disable in table head
 */
export type HeadData = {
  id: Id;
  type:
    | 'number'
    | 'date'
    | 'datetime'
    | 'string'
    | 'array'
    | 'ChildInputTypeArray'
    | 'ChildInputType'
    | 'boolean';
  disablePadding: boolean;
  label?: string;
  isScrollable?: boolean; // added for cells to make them scrollable
  renderCustomHeaderCell?: (headData: HeadData) => React.ReactNode;
  renderCustomDataCell?: (dataItem: any, headData: HeadData) => React.ReactNode;
  renderDataPostfix?: (dataItem: any) => React.ReactNode;
};

type PropsType = {
  onSelectAllClick: React.ChangeEventHandler;
  order: 'desc' | 'asc';
  orderBy: Id;
  selectedData: Array<Id>;
  currentPageData: Array<any>;
  onSort: Function;
  checkable: boolean;
  hasRowMenuColumn: boolean;
  data: Array<HeadData>;
  tools: Tools;
};

const EnhancedTableHead: React.FunctionComponent<PropsType> = props => {
  const sortHandler = (id: Id) => (
    event: React.MouseEvent<HTMLInputElement>,
  ) => {
    props.onSort(event, id);
  };

  const {
    onSelectAllClick,
    order,
    orderBy,
    selectedData,
    currentPageData,
    checkable = false,
    hasRowMenuColumn,
    tools,
    data,
  } = props;
  const allChecked =
    currentPageData.every(d =>
      selectedData.map((s: any) => s.id).includes(d.id),
    ) && selectedData.length > 0;
  const indeterminate =
    currentPageData.some(d =>
      selectedData.map((s: any) => s.id).includes(d.id),
    ) && !allChecked;

  const { showSort = false } = tools;
  return (
    <TableHead>
      <TableRow>
        {checkable ? (
          <TableCell
            key="head-checkbox"
            padding="checkbox"
            style={{
              width: 40,
            }}
          >
            <Checkbox
              indeterminate={indeterminate}
              checked={allChecked}
              onChange={onSelectAllClick}
            />
          </TableCell>
        ) : null}

        {data.map(row => {
          let cellContent = null;
          if (row.renderCustomHeaderCell) {
            cellContent = row.renderCustomHeaderCell(row);
          } else {
            cellContent = showSort ? (
              row.label || ''
            ) : (
              <Tooltip
                title={translate.t('laSort')}
                placement={
                  row.type === 'number' ? 'bottom-end' : 'bottom-start'
                }
                enterDelay={300}
              >
                <TableSortLabel
                  active={orderBy === row.id}
                  direction={order}
                  onClick={sortHandler(row.id)}
                >
                  {row.label || ''}
                </TableSortLabel>
              </Tooltip>
            );
          }

          return (
            <TableCell
              key={row.id}
              align={row.type === 'number' ? 'right' : 'left'}
              padding={row.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === row.id ? order : false}
              style={{ fontSize: '0.9rem' }}
            >
              {cellContent}
            </TableCell>
          );
        })}
        {hasRowMenuColumn ? (
          <TableCell key="head-menu" padding="none">
            &nbsp;
          </TableCell>
        ) : null}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
