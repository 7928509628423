import {
  CostCenter,
  getEmployeeName,
  getLegalEntity,
  OrganizationData,
} from '@/old/utils/helper';
import moment from 'moment/moment';
import {
  commonWorkflowsDataStructureMappingForUI,
  commonWorkflowsReportDataMapping,
  mapProposalValueChanges,
  WorkflowEntryPayload,
  WorkflowReportEmpDetail,
} from '@/app/components/WorkflowsReport/ReportTypes/Common';
import {
  InternalPositionWorkflowReportPayloadEntry,
  InternalPositionProposalPayload,
  InternalPositionWorkflowReportEntry,
} from '@/app/components/WorkflowsReport/ReportTypes/InternalPosition/types';
import { getActiveEnumNameByCode } from '@/app/utils/helper';

const GLOBAL: any = window;

const resolveUnitDetails = (
  unitId: number,
  details: WorkflowReportEmpDetail,
) => {
  if (details.fEmpUnitDetails.fOrgUnitId !== unitId) {
    const foundUnit = (GLOBAL.jsonOrgUnitData as OrganizationData[]).find(
      u => u.fOrgUnitId === unitId,
    );

    return {
      unitId: unitId,
      unit: !!foundUnit ? foundUnit.fOrgUnitName : '',
      unitNo: !!foundUnit ? foundUnit.fOrgUnitNumber : '',
    };
  }

  return {
    unitId: details.fEmpUnitDetails.fOrgUnitId,
    unit: details.fEmpUnitDetails.fOrgUnitName,
    unitNo: details.fEmpUnitDetails.fOrgUnitNumber,
  };
};

const resolveCostCenterDetails = (
  costCenterId: number,
  details: WorkflowReportEmpDetail,
) => {
  if (details.fEmpCostCenterDetails.fEmpCostCenterId !== costCenterId) {
    const foundCostCenter = (GLOBAL.jsonCostCenters as CostCenter[]).find(
      cc => cc.fCostCenterId === costCenterId,
    );

    return {
      costCenter: !!foundCostCenter ? foundCostCenter.fCostCenterName : '',
    };
  }

  return {
    costCenter: details.fEmpCostCenterDetails.fEmpCostCenterName,
  };
};

const mapProposalPayloadData = (
  proposal: InternalPositionProposalPayload,
  details: WorkflowReportEmpDetail,
) => {
  const legalEntity = getLegalEntity(proposal.fLegalEntity);
  const positionName = getActiveEnumNameByCode(
    'EMPLOYEE_POSITION',
    proposal.fPositionFK,
  );

  const unitId = parseInt(proposal.fUnitFK, 10);
  const costCenterId = parseInt(proposal.fCostCenter, 10);

  return {
    legalEntity: legalEntity.fLegalEntityName,
    position: positionName || '',
    jobTitle: proposal.fJobTitle,
    info: proposal.fInfo,
    ...resolveUnitDetails(unitId, details),
    startDate: moment(proposal.fStartDate, 'YYYY-MM-DD').format('DD.MM.YYYY'),
    endDate: !!proposal.fEndDate
      ? moment(proposal.fEndDate, 'YYYY-MM-DD').format('DD.MM.YYYY')
      : '',
    ...resolveCostCenterDetails(costCenterId, details),
    costCenterPct: proposal.fCostCenterPct,
    managerId: getEmployeeName(+proposal.fManagerFK),
  };
};

const mapPayloadDataToReportEntry = (
  details: WorkflowReportEmpDetail,
  workflowEntry: WorkflowEntryPayload,
  proposal: InternalPositionProposalPayload,
  reference: InternalPositionProposalPayload,
): InternalPositionWorkflowReportEntry => {
  const { proposal: mappedProposal, changes } = mapProposalValueChanges(
    mapProposalPayloadData(proposal, details),
    mapProposalPayloadData(reference, details),
  );

  return {
    ...commonWorkflowsReportDataMapping(details, workflowEntry),
    ...mappedProposal,
    referenceValues: changes,
  };
};

export const mapIntPositionWorkflowsForUI = (
  data: InternalPositionWorkflowReportPayloadEntry[],
): InternalPositionWorkflowReportEntry[] => {
  return commonWorkflowsDataStructureMappingForUI(
    data,
    mapPayloadDataToReportEntry,
  );
};
