import moment from 'moment/moment';
import {
  commonWorkflowsDataStructureMappingForUI,
  commonWorkflowsReportDataMapping,
  commonWorkflowsReportUnitDataMapping,
  mapProposalValueChanges,
  WorkflowEntryPayload,
  WorkflowReportEmpDetail,
} from '@/app/components/WorkflowsReport/ReportTypes/Common';
import {
  EmploymentDetailsWorkflowReportPayloadEntry,
  EmploymentDetailsProposalPayload,
  EmploymentDetailsWorkflowReportEntry,
} from '@/app/components/WorkflowsReport/ReportTypes/EmploymentDetails/types';

const mapProposalPayloadData = (proposal: EmploymentDetailsProposalPayload) => {
  return {
    empStatus: proposal.fEmploymentStatusFK,
    pg1: proposal.fPersonnelGroup1FK,
    pg2: proposal.fPersonnelGroup2FK,
    pg3: proposal.fPersonnelGroup3FK,
    pg4: proposal.fPersonnelGroup4FK,
    pg5: proposal.fPersonnelGroup5FK,
    startDate: moment(proposal.fStartDate, 'YYYY-MM-DD').format('DD.MM.YYYY'),
    endDate: !!proposal.fEndDate
      ? moment(proposal.fEndDate, 'YYYY-MM-DD').format('DD.MM.YYYY')
      : '',
    info: proposal.fInfo,
    jobGrade: proposal.fJobGradeFK,
    taxNumber: proposal.fTaxNumber,
    vacBalCalcUseSixDays: proposal.fVacBalCalcUseSixDays,
    calcHoursBalance: proposal.fCalcHoursBalance,
  };
};

const mapPayloadDataToReportEntry = (
  details: WorkflowReportEmpDetail,
  workflowEntry: WorkflowEntryPayload,
  proposal: EmploymentDetailsProposalPayload,
  reference: EmploymentDetailsProposalPayload,
): EmploymentDetailsWorkflowReportEntry => {
  const { proposal: mappedProposal, changes } = mapProposalValueChanges(
    mapProposalPayloadData(proposal),
    mapProposalPayloadData(reference),
  );

  return {
    ...commonWorkflowsReportDataMapping(details, workflowEntry),
    ...commonWorkflowsReportUnitDataMapping(details),
    ...mappedProposal,
    referenceValues: changes,
  };
};

export const mapEmpDetailsWorkflowsForUI = (
  data: EmploymentDetailsWorkflowReportPayloadEntry[],
): EmploymentDetailsWorkflowReportEntry[] => {
  return commonWorkflowsDataStructureMappingForUI(
    data,
    mapPayloadDataToReportEntry,
  );
};
